import React from 'react';
// import sections
// import IndoorGallerySection from '../components/sections/IndoorGallerySection';
import Cta from '../components/sections/Cta';
import ScrollTopButton from '../utils/ScrollTopButton';
import { useLocation } from 'react-router-dom';

const IndoorGallerySection = React.lazy(() => import('../components/sections/IndoorGallerySection'));

const IndoorGallery = () => {

  const location = useLocation();

  return (
    <>
      <React.Suspense fallback={<div/>}>
      <IndoorGallerySection sortTag={location.state?.id || "all"}/>
      </React.Suspense>
      <Cta split />
      <ScrollTopButton scrollAfter={400} />
    </>
  );
}

export default IndoorGallery;