import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import App from './App';
import ScrollToTop from './utils/ScrollToTop';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
// import reportWebVitals from './reportWebVitals';

import './assets/scss/style.scss';
import { AuthProvider } from './utils/AuthProvider';

// const history = createBrowserHistory();

ReactDOM.render(
  <React.StrictMode>
    {/* <Router history={history}> */}
    <BrowserRouter>
      <AuthProvider>
        <ScrollToTop>
          <Routes>
            <Route path="/*" element={<App />} />
          </Routes>
        </ScrollToTop>
      </AuthProvider>
    </BrowserRouter>
    {/* </Router> */}
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
