import React from 'react';
// import sections
import FeaturesSplitContact from '../components/sections/FeaturesSplitContact';
import MapSection from '../components/sections/MapSection';
import ScrollTopButton from '../utils/ScrollTopButton';

const Contact = () => {

  return (
    <>
      <FeaturesSplitContact />
      <MapSection topDivider/>
      <ScrollTopButton scrollAfter={800} />
    </>
  );
}

export default Contact;