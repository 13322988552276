import React, { useRef, useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import ScrollReveal from './utils/ScrollReveal';
import RequireAuth from './utils/RequireAuth';
import { Helmet, HelmetProvider } from 'react-helmet-async';
// import ReactGA from 'react-ga';

// Layouts
import Layout from './layouts/Layout';

// Views
import Home from './views/Home';
import AboutUs from './views/AboutUs';
import IndoorGallery from './views/IndoorGallery';
import OutdoorGallery from './views/OutdoorGallery';
import Contact from './views/Contact';
// import Login from './views/Login';
// import Admin from './views/Admin';
// import Missing from './views/Missing';

// import Register from './views/Register';


// const Home = React.lazy(() => import('./views/Home'));
// const AboutUs = React.lazy(() => import('./views/AboutUs'));
// const IndoorGallery = React.lazy(() => import('./views/IndoorGallery'));
// const OutdoorGallery = React.lazy(() => import('./views/OutdoorGallery'));
// const Contact = React.lazy(() => import('./views/Contact'));
const Login = React.lazy(() => import('./views/Login'));
const Admin = React.lazy(() => import('./views/Admin'));
const Missing = React.lazy(() => import('./views/Missing'));


// Initialize Google Analytics
// ReactGA.initialize(process.env.REACT_APP_GA_CODE);

// const trackPage = page => {
//   ReactGA.set({ page });
//   ReactGA.pageview(page);
// };

const App = () => {
  const childRef = useRef();
  let location = useLocation();

  useEffect(() => {
    // const page = location.pathname;
    document.body.classList.add('is-loaded');
    childRef.current.init();
    // trackPage(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <div>
    <HelmetProvider>
      <Helmet prioritizeSeoTags>
        <title>Peter Deboel Schrijnwerkerij</title>
        <meta charset="utf-8" />
        <meta name="description" content="Wij maken jouw houten droomproject in binnen- of buitenschrijnwerk. Wij leveren maatwerk in ramen, deuren, keukens, badkamers etc." />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content="nl_BE" />
        <meta property="og:title" content="Peter Deboel Schrijnwerkerij | houten binnen- en buitenschrijnwerk" />
        <meta property="og:description" content="Wij maken jouw houten droomproject in binnen- of buitenschrijnwerk. Wij leveren maatwerk in ramen, deuren, keukens, badkamers etc." />
        <meta property="og:url" content="https://www.pdeboel.be" />
        <meta property="og:site_name" content="Schrijnwerkerij Peter Deboel" />
        {/* <meta property="og:image" content="" /> */}
        {/* <meta name="twitter:card" content="" /> */}


        {/* <link rel="preload" as="image" href="./assets/images/slider/Beauty-940x350.png" type="image/webp"></link> */}
      </Helmet>
      <ScrollReveal
        ref={childRef}
        children={() => (
          <React.Suspense fallback={<div></div>}>
          <Routes>
            <Route path="/" element={<Layout />}>
              {/* public routes */}
              <Route path="/" element={<Home />} />
              <Route path="/aboutus" element={<AboutUs />} />
              <Route path="/indoor" element={<IndoorGallery />} />
              <Route path="/outdoor" element={<OutdoorGallery />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/login" element={<Login />} />

              {/* protected routes */}
              <Route element={<RequireAuth />}>
                <Route path="/admin" element={<Admin />} />
                {/* <Route path="/register" element={<Register />} /> */}
              </Route>

              {/* catch all */}
              <Route path="*" element={<Missing />} />
            </Route>
          </Routes>
          </React.Suspense>
        )}
      />
    </HelmetProvider>
    </div>
  );
};

export default App;
