import React from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import Image from '../elements/Image';
// import Swiper core and required modules
import { Navigation, Pagination, Autoplay, EffectFade} from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'material-icons/iconfont/material-icons.css';

// Swiper style imports (must be direct because React doesn't support pure ESM yet)
import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';
import 'swiper/scss/effect-fade';

import Slide1 from "../../assets/images/slider/Beauty-1280x476.jpg";
// import Slide2 from "../../assets/images/slider/Hal-940x350.png";
import Slide3 from "../../assets/images/slider/Trap-940x350.jpg";
import Slide4 from "../../assets/images/slider/Terras-1280x476.jpg";
import { Helmet } from 'react-helmet-async';

// install Swiper modules
// SwiperCore.use([Pagination,Navigation,Autoplay,EffectFade]);

const propTypes = {
    ...SectionProps.types
}
  
const defaultProps = {
    ...SectionProps.defaults
}

const Slider = ({
    className,
    topOuterDivider,
    bottomOuterDivider,
    topDivider,
    bottomDivider,
    hasBgColor,
    invertColor,
    ...props
  }) => {

  const outerClasses = classNames(
      'swiper section center-content',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
  );
    
  const innerClasses = classNames(
      'swiper-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
  );
      
  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <div className="swiper-content">
            {/* Add text above swiper here */}
          </div>
          <div className="swiper-figure reveal-fade-in-1 illustration-element-01" data-reveal-value="20px" data-reveal-delay="400">
            <Swiper
              // style={{'--swiper-navigation-color': '#fff','--swiper-pagination-color': '#fff'}}
              slidesPerView={1}
              // spaceBetween={30}
              effect={"fade"}
              fadeEffect={{crossFade: true}}
              speed={1000}
              centeredSlides={true}
              loop={true}
              pagination={{
                el: ".swiper-pagination1",
                clickable: true,
                renderBullet: function (index, className) {
                  return `<span class="swiper-pagination-bullet"></span>`;
                }
              }}
              navigation={{
                prevEl: ".slide-prevbutton",
                nextEl: ".slide-nextbutton"
              }}
              autoplay={{
                delay: 3000,
                disableOnInteraction: false
              }}
              modules={[Autoplay, Pagination, Navigation, EffectFade]}
              className="mainSwiper"
            >
              <SwiperSlide>     {/* 1280x720 */}
                <Helmet>
                  <link rel="preload" as="image" href={Slide1} width={1280} height={476} />
                </Helmet>
                <Image
                  src={Slide1}
                  alt="Beautysalon inrichting"
                  width={1280}
                  height={476} />
              </SwiperSlide>
              {/* <SwiperSlide>
                <Image
                  src={Slide2}
                  alt="Toonzaal"
                  width={1280}
                  height={476} />
              </SwiperSlide> */}
              <SwiperSlide>
                <Image
                  src={Slide3}
                  alt="Eiken trap"
                  width={1280}
                  height={476} />
              </SwiperSlide>
              <SwiperSlide>
                <Image
                  src={Slide4}
                  alt="Terras"
                  width={1280}
                  height={476} />
              </SwiperSlide>
            </Swiper>
          <div className="slide-arrow slide-arrow__prev slide-prevbutton"><i className="material-icons md-60">navigate_before</i></div>
          <div className="slide-arrow slide-arrow__next slide-nextbutton"><i className="material-icons md-60">navigate_next</i></div>
          <div className="swiper-pagination1"></div>
          </div>
        </div>
      </div>
    </section>
  );
};

Slider.propTypes = propTypes;
Slider.defaultProps = defaultProps;

export default Slider;