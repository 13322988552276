import axios from 'axios';
// export const API_URL = 'http://localhost:3500/';     // dev
export const API_URL = '/api/';     // production
export default axios.create({
    baseURL: API_URL
});

export const axiosPrivate = axios.create({
    baseURL: API_URL,
    headers: { 'Content-Type': 'application/json' },
    withCredentials: true
});