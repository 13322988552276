import React from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
// import Swiper core and required modules
import { Navigation, Pagination, Autoplay, EffectFade} from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'material-icons/iconfont/material-icons.css';

// Swiper style imports (must be direct because React doesn't support pure ESM yet)
import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';
import 'swiper/scss/effect-fade';

import Invisidoor1 from "../../assets/images/invisidoors/invisidoor1.jpg";
import Invisidoor2 from "../../assets/images/invisidoors/invisidoor2.jpg";
import Invisidoor3 from "../../assets/images/invisidoors/invisidoor3.jpg";
import Invisidoor4 from "../../assets/images/invisidoors/invisidoor4.jpg";
import Invisidoor5 from "../../assets/images/invisidoors/invisidoor5.jpg";
import Invisidoor6 from "../../assets/images/invisidoors/invisidoor6.jpg";
import Invisidoor7 from "../../assets/images/invisidoors/invisidoor7.jpg";
import Invisidoor8 from "../../assets/images/invisidoors/invisidoor8.jpg";


// install Swiper modules
// SwiperCore.use([Pagination,Navigation,Autoplay,EffectFade]);

const propTypes = {
    ...SectionProps.types
}
  
const defaultProps = {
    ...SectionProps.defaults
}

const Slider = ({
    className,
    topOuterDivider,
    bottomOuterDivider,
    topDivider,
    bottomDivider,
    hasBgColor,
    invertColor,
    invertMobile,
    invertDesktop,
    alignTop,
    ...props
  }) => {

  const outerClasses = classNames(
      'swiper section',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
  );
    
  const innerClasses = classNames(
      'swiper-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
  );

  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );

  const sectionHeader = {
    title: 'Invisidoors',
    paragraph: 'Een uniek product voor moderne woningen.'
  };
      
  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={splitClasses}>

          <div className="split-item">
            <div className="split-item-content center-content-mobile reveal-fade-in-1" data-reveal-container=".split-item">
              {/* <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                Modern en strak
              </div> */}
              {/* <h3 className="mt-0 mb-12">
                Keukens
              </h3> */}
              <p className="m-0 h6-mobile">
                Invisidoors zijn speciale deuren, met een onzichtbaar deurkader. 
                Dit is de ideale aanvulling voor wie een moderne of strakke look wil in zijn woning.
                Er wordt gebruik gemaakt van een onzichtbaar aluminium kader dat ingepleisterd kan worden, waardoor de deur mooi in lijn ligt met de wand en er creatief met de ruimte kan worden omgegaan.
                <br/>
                Een schuifdeurvariant is ook beschikbaar, waarbij het gehele systeem in de muur geïntegreerd wordt en zelfs een deurgreep overbodig is.
                <br/> <br/>
                Heeft u graag een demonstratie of meer uitleg hierover? Kom zeker eens langs in onze <a href='/contact'>showroom</a>!
              </p>
            </div>
            <div className={
              classNames(
                'split-item-image center-content-mobile',
              )}
              data-reveal-container=".split-item">
              
                <div className="swiper-content">
                  {/* Add text above swiper here */}
                </div>
                <div className="swiper-figure reveal-fade-in-1 illustration-element-01" data-reveal-value="20px" data-reveal-delay="400">
                  <Swiper
                    // style={{'--swiper-navigation-color': '#fff','--swiper-pagination-color': '#fff'}}
                    slidesPerView={1}
                    // spaceBetween={30}
                    effect={"fade"}
                    fadeEffect={{crossFade: true}}
                    speed={1000}
                    centeredSlides={true}
                    loop={true}
                    pagination={{
                      el: ".swiper-pagination2",
                      clickable: true,
                      renderBullet: function (index, className) {
                        return `<span class="swiper-pagination-bullet"></span>`;
                      }
                    }}
                    navigation={{
                      prevEl: ".slide-prevbutton",
                      nextEl: ".slide-nextbutton"
                    }}
                    autoplay={{
                      delay: 3000,
                      disableOnInteraction: false
                    }}
                    modules={[Autoplay, Pagination, Navigation, EffectFade]}
                    className="mainSwiper2"
                  >
                    <SwiperSlide>     {/* 1280x720 */}
                      <img
                        src={Invisidoor1}
                        alt="Invisible door frame"
                        width={768}
                        height={513}
                        loading="lazy" />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img
                        src={Invisidoor2}
                        alt="Invisible door frame"
                        width={768}
                        height={513}
                        loading="lazy" />
                    </SwiperSlide>
                    <SwiperSlide>
                      <img
                        src={Invisidoor3}
                        alt="Invisible door frame"
                        width={768}
                        height={513}
                        loading="lazy"/>
                    </SwiperSlide>
                    <SwiperSlide>
                      <img
                        src={Invisidoor4}
                        alt="Invisible door frame"
                        width={768}
                        height={513} 
                        loading="lazy"/>
                    </SwiperSlide>
                    <SwiperSlide>
                      <img
                        src={Invisidoor5}
                        alt="Invisible door frame"
                        width={768}
                        height={513} 
                        loading="lazy"/>
                    </SwiperSlide>
                    <SwiperSlide>
                      <img
                        src={Invisidoor6}
                        alt="Invisible door frame"
                        width={768}
                        height={513} 
                        loading="lazy"/>
                    </SwiperSlide>
                    <SwiperSlide>
                      <img
                        src={Invisidoor7}
                        alt="Invisible door frame"
                        width={768}
                        height={513} 
                        loading="lazy"/>
                    </SwiperSlide>
                    <SwiperSlide>
                      <img
                        src={Invisidoor8}
                        alt="Invisible door frame"
                        width={768}
                        height={513} 
                        loading="lazy"/>
                    </SwiperSlide>
                  </Swiper>
                <div className="slide-arrow slide-arrow__prev slide-prevbutton"><i className="material-icons md-60">navigate_before</i></div>
                <div className="slide-arrow slide-arrow__next slide-nextbutton"><i className="material-icons md-60">navigate_next</i></div>
                <div className="swiper-pagination2"></div>
                </div>
            </div>

          </div>
          </div>
        </div>
      </div>
    </section>
  );
};

Slider.propTypes = propTypes;
Slider.defaultProps = defaultProps;

export default Slider;