import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import Image from '../../elements/Image';

import logoimage from '../../../assets/images/pdeboel-logo-extended-4x1.svg';
import logolight from '../../../assets/icons/pdeboel-logo-extended-light.svg';

const Logo = ({
  className,
  imgClassName,
  light,
  ...props
}) => {

  const classes = classNames(
    'brand',
    className
  );

  return (
    <div
      {...props}
      className={classes}
    >
      <h1 className="m-0">
        <Link to="/">
          <Image
            className={imgClassName}
            src={(light && logolight) || logoimage}
            alt="Logo"
            width={400}
            height={100} />
        </Link>
      </h1>
    </div>
  );
}

export default Logo;