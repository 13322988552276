import React from 'react';
// import sections
// import FeaturesSplitAboutUs from '../components/sections/FeaturesSplitAboutUs';
import Cta from '../components/sections/Cta';
import ScrollTopButton from '../utils/ScrollTopButton';

const FeaturesSplitAboutUs = React.lazy(() => import('../components/sections/FeaturesSplitAboutUs'));

const AboutUs = () => {

  return (
    <>
      <React.Suspense fallback={<div />}>
      <FeaturesSplitAboutUs />
      </React.Suspense>
      <Cta split />
      <ScrollTopButton scrollAfter={800} />
    </>
  );
}

export default AboutUs;