import React from 'react';
// import sections
// import OutdoorGallerySection from '../components/sections/OutdoorGallerySection';
import ScrollTopButton from '../utils/ScrollTopButton';
import { useLocation } from 'react-router-dom';
import Cta from '../components/sections/Cta';

const OutdoorGallerySection = React.lazy(() => import('../components/sections/OutdoorGallerySection'));

const OutdoorGallery = () => {

  const location = useLocation();

  return (
    <>
      <React.Suspense fallback={<div/>}>
      <OutdoorGallerySection sortTag={location.state?.id || "all"}/>
      </React.Suspense>
      <Cta split />
      <ScrollTopButton scrollAfter={400}/>
    </>
  );
}

export default OutdoorGallery;