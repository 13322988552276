import React, { useState, useRef } from 'react';
import Input from '../../elements/Input';
import Button from '../../elements/Button';
import axios from '../../../api/axios';
import ReCAPTCHA from 'react-google-recaptcha';
import classNames from 'classnames';
import DOMPurify from 'dompurify';

const ContactForm = ({ ...props }) => {

  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [tel, setTel] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [response, setResponse] = useState("");
  const [errMsg, setErrMsg] = useState(false);

  const captchaRef = useRef(null);

  async function handleSubmit(e) {
    e.preventDefault();

    let captchaVerified = false;
    const captchaToken = captchaRef.current.getValue();
    captchaRef.current.reset();

    await axios.post('recaptcha', {captchaToken})
      .then(res => {
        if (res?.status === 200) {
          captchaVerified = true;
        }
      })
      .catch((err) => {
        setErrMsg(true);
        if (!err?.response) {
          setResponse('Error: probeer het a.u.b. opnieuw, of stuur ons een e-mail');
        } else if (err.response?.status === 401) {
          setResponse('Vul a.u.b. de reCAPTCHA correct in');
        } else {
          setResponse('Er is iets misgegaan...');
        }
      })

    if (captchaVerified) {
      const data = {
        name: name,
        address: address,
        tel: tel,
        subject: subject,
        message: message,
      };
      try {
        await axios.post('sendmail', data)
          .then(function (response) {
            setResponse(response.data);
            resetForm();
          }).catch((err) => {
            setErrMsg(true);
            setResponse("Er is iets misgegaan...");
            console.log(err);
          })
      } catch (err) {
        console.log(err);
        setErrMsg(true);
        setResponse("Error: probeer het a.u.b. opnieuw, of stuur ons een e-mail");
      }
    }
  }

  const resetForm = () => {
    setName("");
    setAddress("");
    setTel("");
    setSubject("");
    setMessage("");
    setErrMsg("");
  }

  // const handleCancel = (e) => {
  //   e.preventDefault();
  //   resetForm();
  //   setResponse("");
  // }

  return (
    <section {...props}
    >
      <div className="contact-form-container">
        <form id="contact-form"
              onSubmit={handleSubmit}
              method="POST"
        >
          <fieldset>
            <div className="mb-12">
              <Input
                type="text"
                name="naam"
                label="Naam"
                value={name}
                onChange={(e) => setName(DOMPurify.sanitize(e.target.value))}
                required
              />
            </div>
            <div className="mb-12">
              <Input
                type="email"
                label="Email"
                name="email"
                value={address}
                // status="success"
                onChange={(e) => setAddress(DOMPurify.sanitize(e.target.value))}
                required
              />
            </div>
            <div className="mb-12">
              <Input
                type="tel"
                label="Telefoonnummer"
                name="tel"
                value={tel}
                placeholder="(optioneel)"
                // status="success"
                onChange={(e) => setTel(DOMPurify.sanitize(e.target.value))}
              />
            </div>
            <div className="mb-12">
              <Input
                type="text"
                label="Onderwerp"
                name="onderwerp"
                value={subject}
                // status="error"
                // hint="komt onder textbox"
                onChange={(e) => setSubject(DOMPurify.sanitize(e.target.value))}
                required 
              />
            </div>
            <div className="mb-24">
              <Input
                type="textarea"
                label="Bericht" 
                onChange={(e) => setMessage(DOMPurify.sanitize(e.target.value))}
                value={message}
                required
              />
            </div>
            <div className="mt-12">
              <div className="recaptcha-group">
                <div className="send-button">
                <Button color="primary">Verzenden</Button>
                </div>
                <div className="recaptcha">
                <ReCAPTCHA 
                  sitekey={process.env.REACT_APP_SITE_KEY}
                  ref={captchaRef}
                  size="normal"
                />
                </div>
                {/* <a className="button-link text-xs cursor-pointer" onClick={handleCancel}>Annuleren</a> */}
              </div>
            </div>
            <div className={classNames(errMsg ? 'errmsg' : 'offscreen', 'mail-response')}>
            { response ? response : 
              <>
              <br />
              </> }
            </div>
          </fieldset>
        </form>
      </div>
    </section>
  )
}


export default ContactForm;