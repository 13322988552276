// Using react router dom V6
import { Outlet } from "react-router-dom";
import Header from "../components/layout/Header";
import Footer from "../components/layout/Footer";

const Layout = () => {
  return (
    <>
      <Header navPosition="right" className="reveal-fade-in-1" />
      <main className="site-content">
        <Outlet />
      </main>
      <Footer />
    </>
  );
}

export default Layout;