import React from 'react';
// import sections
import Slider from '../components/sections/Slider';
import SliderInvisidoor from '../components/sections/SliderInvisidoor';
import FeaturesSplit from '../components/sections/FeaturesSplit';
// import Testimonial from '../components/sections/Testimonial';
import Cta from '../components/sections/Cta';
import FixedBackground from '../components/sections/FixedBackground';
import ScrollTopButton from '../utils/ScrollTopButton';

const Home = () => {

  return (
    <>
      {/* <Slider className="illustration-section-01" /> */}
      <Slider />
      <FeaturesSplit invertMobile imageFill />
      <FixedBackground />
      <SliderInvisidoor />
      {/* <Testimonial topDivider /> */}
      <Cta split />
      <ScrollTopButton scrollAfter={1200} />
    </>
  );
}

export default Home;