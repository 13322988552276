import React from 'react'
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';

const propTypes = {
  ...SectionProps.types,
}

const defaultProps = {
  ...SectionProps.defaults,
}

const MapSection = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {

  const outerClasses = classNames(
    'map-section section center-content-mobile',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'map-section-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider',
  );  

  const sectionHeader = {
    title: 'Locatie',
    paragraph: ''
  };
  
  return (
    <section 
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
        
          <div className="map-content">
            <iframe 
              title="map"
              className="reveal-from-bottom"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2494.5809492897442!2d5.096706015648931!3d51.30043527960166!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c6b5e73961b853%3A0x63c1adf0c8977754!2sPeter%20Deboel!5e0!3m2!1sen!2sbe!4v1645108393133!5m2!1sen!2sbe"
              width="100%"
              height="100%"
              style={{ border: "0" }}
              allowFullScreen
              loading="lazy"
            >
            </iframe>
          </div>
        </div>
      </div>
    </section>
  )
}

MapSection.propTypes = propTypes;
MapSection.defaultProps = defaultProps;

export default MapSection