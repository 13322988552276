import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
// import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';
import ContactForm from './partials/ContactForm';

import toonzaal from './../../assets/images/contact/toonzaal2.jpg';
import pdeboellocatie from './../../assets/images/contact/pdeboel-locatie.png';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

const FeaturesSplitContact = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {

  const outerClasses = classNames(
    'features-split-contact section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-split-contact-inner section-inner reveal-fade-in-1',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );

  // const sectionHeader = {
  //   title: 'Welkom bij onze toonzaal',
  //   paragraph: ''
  // };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          {/* <SectionHeader data={sectionHeader} className="center-content" /> */}
          <div className={splitClasses}>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <h3 className="mt-0 mb-12">
                  Toonzaal / Contact
                  </h3>
                <p className="m-0 text-xs fw-400">
                  Voor verdere info of advies, bent u altijd welkom voor een bezoek aan onze toonzaal.
                  <br/>
                  Samen zoeken wij de beste opties voor uw project!
                  </p>
                <br/>
                <p className="m-0 text-xs fw-400">
                  Peter Deboel BVBA
                  <br/>
                  Hoge Mauw 1355
                  <br/>
                  2370 Arendonk
                  </p>
                <br/>
                <div className="desktop-tel">
                  <p className="m-0 text-xs fw-400">
                    <svg id="tel-icon-1" xmlns="http://www.w3.org/2000/svg" width="16" height="22" viewBox="0 0 20 22"><path d="M20 22.621l-3.521-6.795c-.008.004-1.974.97-2.064 1.011-2.24 1.086-6.799-7.82-4.609-8.994l2.083-1.026-3.493-6.817-2.106 1.039c-7.202 3.755 4.233 25.982 11.6 22.615.121-.055 2.102-1.029 2.11-1.033z"/></svg>
                      +32 14 95 31 10
                    <br/>
                    <svg id="tel-icon-2" xmlns="http://www.w3.org/2000/svg" width="16" height="22" viewBox="0 0 20 22"><path d="M20 22.621l-3.521-6.795c-.008.004-1.974.97-2.064 1.011-2.24 1.086-6.799-7.82-4.609-8.994l2.083-1.026-3.493-6.817-2.106 1.039c-7.202 3.755 4.233 25.982 11.6 22.615.121-.055 2.102-1.029 2.11-1.033z"/></svg>
                      +32 476 50 25 34
                    <br/>
                  </p>
                </div>
                <div className="mobile-tel">
                  <p className="m-0 text-xs fw-400">
                    <svg id="tel-icon-3" xmlns="http://www.w3.org/2000/svg" width="16" height="22" viewBox="0 0 20 22"><path d="M20 22.621l-3.521-6.795c-.008.004-1.974.97-2.064 1.011-2.24 1.086-6.799-7.82-4.609-8.994l2.083-1.026-3.493-6.817-2.106 1.039c-7.202 3.755 4.233 25.982 11.6 22.615.121-.055 2.102-1.029 2.11-1.033z"/></svg>
                      <a href="tel:+3214953110">+32 14 95 31 10</a>
                    <br/>
                    <svg id="tel-icon-4" xmlns="http://www.w3.org/2000/svg" width="16" height="22" viewBox="0 0 20 22"><path d="M20 22.621l-3.521-6.795c-.008.004-1.974.97-2.064 1.011-2.24 1.086-6.799-7.82-4.609-8.994l2.083-1.026-3.493-6.817-2.106 1.039c-7.202 3.755 4.233 25.982 11.6 22.615.121-.055 2.102-1.029 2.11-1.033z"/></svg>
                      <a href="tel:+32476502534">+32 476 50 25 34</a>
                    <br/>
                  </p>
                </div>
                <p className="m-0 text-xs fw-400">
                <svg id="email-icon" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24"><path d="M12 12.713l-11.985-9.713h23.97l-11.985 9.713zm0 2.574l-12-9.725v15.438h24v-15.438l-12 9.725z"/></svg>
                  <a className="email-link" href="mailto:info@pdeboel.be">info@pdeboel.be</a>
                </p>
                <br/>
                <p className="m-0 text-sm fw-400">
                  Gelieve altijd op voorhand een afspraak te maken.
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={pdeboellocatie}
                  alt="Toonzaal"
                  width={660}
                  height={366} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                <h3 className="mt-0 mb-12">
                  Stuur ons een bericht
                  </h3>
                <p className="m-0 text-xs fw-400">
                Heeft u vragen of wenst u graag meer info? Stuur ons een mail of vul het formulier hieronder in en wij nemen zo snel mogelijk contact met u op.
                  </p>
                <ContactForm />
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom mobile-remove-contact',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={toonzaal}
                  alt="Features split 02"
                  width={528}
                  height={396} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

FeaturesSplitContact.propTypes = propTypes;
FeaturesSplitContact.defaultProps = defaultProps;

export default FeaturesSplitContact;