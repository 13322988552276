import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';
import { Link } from 'react-router-dom';

// import bannerImage from './../../assets/images/home/banner_pierret_2023.jpg';
// import bannerImageMobile from './../../assets/images/home/banner_pierret_2023_mobile.jpg';

import pierretramen from './../../assets/images/home/pierretramen.jpg';
import keuken from './../../assets/images/home/2021keuken.jpg';
import meubels from './../../assets/images/home/schoonheidsinstituut1.jpg';
import trap from './../../assets/images/home/2021trap.jpg';
// import Banner from './partials/Banner';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

const FeaturesSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {

  const outerClasses = classNames(
    'features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-split-inner section-inner reveal-fade-in-1',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );

  const sectionHeader = {
    title: 'Realiseer uw woondroom',
    paragraph: 'Maak in samenwerking met ons uw woondroom waar! Wij leveren kwaliteitsvol schrijnwerk, op maat gemaakt volgens uw wensen.'
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />


          {/* Desktop banner */}
          {/* <Banner className="banner-desktop" src={bannerImage} width={1280} height={414} alt='Pierret biedt u tijdelijk driedubbele beglazing voor de prijs van dubbele'/> */}
          {/* Mobile banner */}
          {/* <Banner className="banner-mobile" src={bannerImageMobile} width={640} height={325} alt='Pierret biedt u tijdelijk driedubbele beglazing voor de prijs van dubbele'/> */}
          
          
          <div className={splitClasses}>

            <div className="split-item">
              <div className="split-item-content center-content-mobile" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Kies voor kwaliteit
                </div>
                <Link to="/outdoor" state={{ id: "ramen" }}>
                <h3 className="mt-0 mb-12">
                  Pierret Ramen & Deuren
                </h3>
                </Link>
                <p className="m-0 h6-mobile">
                  Wij voorzien u van een breed aanbod aan kwaliteitsvolle ramen en deuren in zowel Hout, PVC, Aluminium en Hout-Aluminium. 
                </p>
                <br/>
                <p className="m-0 h6-mobile">
                  De keuze van goede ramen en deuren is één van de belangrijkste die u moet maken bij de bouw of renovatie van uw woning.
                  Pierret kiezen, is kiezen voor meer dan 60 jaar Belgische kwaliteit, expertise en ervaring. 
                </p>
              </div>
              <Link to="/outdoor" state={{ id: "ramen" }}>
                <div className={
                  classNames(
                    'split-item-image center-content-mobile',
                    imageFill && 'split-item-image-fill'
                  )}
                  data-reveal-container=".split-item">
                  <Image
                    src={pierretramen}
                    alt="Features split 01"
                    width={628}
                    height={338} />
                </div>
              </Link>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Volgens uw wensen
                </div>
                <Link to="/indoor" state={{ id: "keuken" }}>
                <h3 className="mt-0 mb-12">
                  Keukens
                </h3>
                </Link>
                <p className="m-0 h6-mobile">
                  Een keuken is het pronkstuk van je huis. 
                  Vanuit onze passie en kennis ontwerpen wij volgens uw stijl en wensen de ideale keuken.
                  Of het nu in moderne, klassieke stijl of ertussenin is, onze keukens zijn steeds hoogwaardig, goed ontworpen en functioneel.
                  <br/>
                  Alles is mogelijk bij ons, zodat het perfecte eindresultaat kan bekomen worden.
                  </p>
              </div>
              <Link to="/indoor" state={{ id: "keuken" }}>
                <div className={
                  classNames(
                    'split-item-image center-content-mobile reveal-from-bottom',
                    imageFill && 'split-item-image-fill'
                  )}
                  data-reveal-container=".split-item">
                  <Image
                    src={keuken}
                    alt="Features split 02"
                    width={628}
                    height={379} />
                </div>
              </Link>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Voor het hele huis
                </div>
                <Link to="/indoor" state={{ id: "living" }}>
                <h3 className="mt-0 mb-12">
                  Meubels
                </h3>
                </Link>
                <p className="m-0 h6-mobile">
                  Droomt u van een stijlvol interieur? Dan zijn passende meubels zeker belangrijk.
                  Wij ontwerpen en produceren op maat gemaakte meubels voor heel uw huis.
                  Enkele voorbeelden uit ons aanbod zijn: kasten, tafels, badkamermeubels, dressings, bureaus, afkastingen...
                  </p>
              </div>
              <Link to="/indoor" state={{ id: "living" }}>
                <div className={
                  classNames(
                    'split-item-image center-content-mobile reveal-from-bottom',
                    imageFill && 'split-item-image-fill'
                  )}
                  data-reveal-container=".split-item">
                  <Image
                    src={meubels}
                    alt="Features split 03"
                    width={628}
                    height={419} />
                </div>
              </Link>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Hoogwaardig maatwerk
                </div>
                <Link to="/indoor" state={{ id: "trappen" }}>
                <h3 className="mt-0 mb-12">
                  Trappen
                </h3>
                </Link>
                <p className="m-0 h6-mobile">
                  Bent u op zoek naar de geschikte houten trap voor uw nieuwbouwwoning of bestaand huis?
                  Wij bieden een breed gamma aan op maat gemaakte trappen, volledig ontworpen naar uw wensen.
                  <br/>
                  Ons vakmanschap staat ons toe om u alles aan te bieden, van moderne trappen met zwevende tredes tot meer rustieke modellen en alles ertussenin.
                  </p>
              </div>
              <Link to="/indoor" state={{ id: "trappen" }}>
                <div className={
                  classNames(
                    'split-item-image center-content-mobile reveal-from-bottom',
                    imageFill && 'split-item-image-fill'
                  )}
                  data-reveal-container=".split-item">
                  <Image
                    src={trap}
                    alt="Features split 03"
                    width={628}
                    height={538} />
                </div>
              </Link>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;