import React, { useState, useEffect } from 'react';
import 'material-icons/iconfont/material-icons.css';

const ScrollTopButton = ({ scrollAfter }) => {
  const [showTopBtn, setShowTopBtn] = useState(false);

  useEffect(() => {
    const handleVisibleButton = () => {
      setShowTopBtn(window.scrollY > scrollAfter)
    }

    window.addEventListener('scroll', handleVisibleButton);
    return () => {
      window.removeEventListener('scroll', handleVisibleButton); // cleanup function
    }
  }, [scrollAfter]);

  const goToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth',
    });
  };

  return (
    <div className="scroll-top-button">
      {showTopBtn && (
        <i className="material-icons up-arrow-icon" onClick={goToTop}>keyboard_arrow_up</i>
      )}
    </div>
  );
};

export default ScrollTopButton;