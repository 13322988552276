import React from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}
const FixedBackground = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {

  const outerClasses = classNames(
    'fixed-background section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'fixed-background-inner section-inner pt-0',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="parallax" />
        <div className="fixed-container">
          <div className={innerClasses}>
            <div className="emptywindow">

              <div className={classNames('container-sm','m-0','fixed-bg-text','h3')}>
              Traditioneel handwerk in combinatie met nieuwe technologie
              </div>

            </div>
          </div>
        </div>
    </section>
  );
}

FixedBackground.propTypes = propTypes;
FixedBackground.defaultProps = defaultProps;

export default FixedBackground;